/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate, useParams } from 'react-router-dom';

import NavbarWrapper from 'components/layout/NavbarWrapper';
import TabPanel from 'components/shared/atoms/TabPanel';
import TabButton from 'components/shared/atoms/button/TabButton';
import StudentListTable from 'components/shared/molecules/StudentListTable';
import BookIcon from 'assets/svgs/ts/BookIcon';
import StudentIcon from 'assets/svgs/ts/StudentIcon';
import StudentReportIcon from 'assets/svgs/ts/StudentReportIcon';
import 'styles/components/pages/teacherClass.scss';
import QuizClassOverview from 'components/shared/molecules/QuizClass/QuizClassOverview';
import {
  useGeTeacherClassByIdQuery,
} from 'store/ApiCall/classesApiCall';
import CircularLoader from 'components/shared/molecules/Loader';
import QuizReportsByClass from 'components/shared/molecules/QuizReportsByClass';
import clsx from 'clsx';

export default function TeacherClassPage(): JSX.Element {
  const navigate = useNavigate();
  const { classId } = useParams();

  const [tabValue, setTabValue] = React.useState(1);

  const handleChange = (newValue: number): void => {
    setTabValue(newValue);
  };

  // API call for class information
  const { data, isLoading: isGettingClassInformation, refetch } = useGeTeacherClassByIdQuery(classId);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, Number(process.env.REACT_APP_REFETCH_CLASS_INFORMATION));

    return () => { clearInterval(interval); };
  }, [refetch]);

  if (isGettingClassInformation) return <CircularLoader />;

  return (
    <div className='classPageRoot'>
      <NavbarWrapper name={((): JSX.Element => (
        <span className='navbarTitle'>
          <div className='linkElement' onClick={(): void => { navigate(-1); }}>
            My Classes
          </div>
          <span className='seperator'>{'>'}</span>
          <span className='currentElement'>
            {data?.data.class.name}
          </span>
        </span>
      ))()}
      >
        <div className='pageContent'>
          <div className='tabBar'>
            <Tabs onChange={(event, newValue: number): void => { handleChange(newValue); }} value={tabValue}>
              <Tab
                label={(
                  <TabButton
                    icon={<BookIcon className={clsx(tabValue === 1 ? 'fill-white' : 'fill-mono-dull-light')} />}
                    label='Overview'
                  />
                )}
                value={1}
                disableRipple
                className='muiTab'
              />
              <Tab
                label={<TabButton icon={<StudentIcon />} label='Students' />}
                value={2}
                disableRipple
                className='muiTab'
              />
              <Tab
                label={<TabButton icon={<StudentReportIcon />} label='Reports' />}
                value={3}
                disableRipple
                className='muiTab'
              />
            </Tabs>
          </div>
          <div className='pageContent-single-tab'>
            <TabPanel value={tabValue} index={1}>
              <QuizClassOverview classInformation={data?.data} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <StudentListTable />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <QuizReportsByClass classId={classId} />
            </TabPanel>
          </div>
        </div>
      </NavbarWrapper>
    </div>
  );
}
